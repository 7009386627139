<template>
  <div id="app">
    <d3-network :net-nodes="nodes" :net-links="links" :options="options" style="height: 100vh" />
  </div>
</template>

<script>
if (window.location.pathname !== '/alpha' && window.location.pathname !== '/identifier') window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/alpha';

import D3Network from 'vue-d3-network'

const alphaColorMap = {
  '': '#9F9F9F',
  a: '#F28D0C',
  b: '#FC700D',
  c: '#E63C00',
  d: '#FC2A0D',
  e: '#F20C20',
  f: '#D600E6',
  g: '#A90DFC',
  h: '#6C0DFC',
  i: '#1900E6',
  j: '#0D33FC',
  k: '#0C0EF2',
  l: '#0D50FC',
  m: '#0080E6',
  n: '#0DD1FC',
  o: '#0CF2DC',
  p: '#0BD4CF',
  q: '#00EBA2',
  r: '#0BD459',
  s: '#0CF624',
  t: '#3BD40B',
  u: '#8DEB00',
  v: '#CBD40B',
  w: '#F6E00C',
  x: '#F6E40C',
  y: '#D6BA00',
  z: '#EBB400'
}

let nodesAlpha = [];
let nodesIdentifier = [];

let graphData = {
  nodes: [],
  links: [],
  options: {
    force: 1000,
    nodeSize: 20,
    nodeLabels: true,
    linkWidth: 5
  }
};

fetch('https://trie-api.herokuapp.com/view').then(response => response.json()).then(data => {
  for (let i = 0; i < data.length; i++) {
    nodesAlpha.push({
      id: i,
      name: data[i]['value'] === '' ? 'root' : data[i]['value'],
      _color: alphaColorMap[data[i]['value'].charAt(0)],
      _labelClass: data[i]['isFinal'] === true ? 'stroke' : null
    });
    nodesIdentifier.push({
      id: i,
      name: '[' + (data[i]['value'] === '' ? 'root' : data[i]['value']) + '] - ' + data[i]['_id'],
      _color: alphaColorMap[data[i]['value'].charAt(0)],
      _labelClass: data[i]['isFinal'] === true ? 'stroke' : null
    });

    for (let j = 0; j < data.length; j++) {
      if (data[i]['children'].includes(data[j]['_id'])) graphData.links.push({ sid: i, tid: j, _color: alphaColorMap[data[i]['value'].charAt(0)] });
    }
  }

  if (window.location.pathname === '/identifier') graphData.nodes = nodesIdentifier;
  else graphData.nodes = nodesAlpha;
});

addEventListener('keypress', function(event) {
  if (event.key === 'm') {
    if (window.location.pathname === '/identifier') window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/alpha';
    else window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/identifier';
  }
});

export default {
  name: 'App',
  components: {
    D3Network
  },
  data () {
    return graphData;
  }
}
</script>

<style src="vue-d3-network/dist/vue-d3-network.css">
</style>
